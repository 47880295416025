import React from 'react';

function Info() {
  return (
    <div className="column f1 cart-note">
      <div className="note">
        <strong>
          If you would like collection at a specific time please use the order
          notes box to let us know.
        </strong>
      </div>
      <div className="note">
        <h2>CLICK & COLLECT</h2>
        We automatically apply a 10% discount code to all online Click & Collect
        orders and you will see your savings appear on the next page. Orders are
        ready to collect approx. 20 minutes after order.
      </div>
      <div className="note">
        We recommend creating a customer account when you checkout so that you
        can access your previous meal orders and receive our latest offers.
        <br />
        Please contact us at the store on{' '}
        <span className="primary">01293 400 063</span> if you have any questions
        or queries about ordering.
      </div>
      <div className="note">
        <h2>TERMS & CONDITIONS</h2>
        Please check our terms and conditions of sale and privacy statement via
        the links below.
      </div>
    </div>
  );
}

export default Info;
