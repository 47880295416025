import React, {useState} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import {useSelector} from 'react-redux';
import {makeSelectMenu} from '../Pages/Shop/selectors';
import {makeSelectProducts} from '../Pages/Cart/selectors';
import {getProductsCount} from '../Libs/product';

const ShopNavbar = () => {
  const {slug} = useParams();
  const {
    push,
    location: {pathname},
  } = useHistory();
  const menu = useSelector(makeSelectMenu());
  const [showNavbar, setShowNavbar] = useState(false);
  const products = useSelector(makeSelectProducts());

  return (
    <section className="navbar" id={showNavbar ? 'toggled-navbar' : ''}>
      <Link to="/" className="nav-logo" />
      <ul className="nav">
        <li className="nav-item dropdown">
          <Link
            to={`/shop/${slug}/menu`}
            className={`dropbtn ${
              pathname === `/shop/${slug}/menu` ? 'active-nav' : ''
            }`}
          >
            MENU
          </Link>
          <ul className="dropdown-content-shop">
            {menu.map((submenu) => (
              <li key={submenu.slug}>
                <HashLink smooth to={`/shop/${slug}/menu#${submenu.slug}`}>
                  {submenu.title}
                </HashLink>
              </li>
            ))}
          </ul>
        </li>
        <Link to={`/shop/${slug}/details`} className="nav-item dropdown">
          <span
            className={`dropbtn ${
              pathname === `/shop/${slug}/details` ? 'active-nav' : ''
            }`}
          >
            STORE DETAILS
          </span>
        </Link>
      </ul>
      <div className="shop-nav">
        <form
          className="nav-item search-menu"
          onSubmit={(e) => push(`/shop/${slug}/search/${e.target[0].value}`)}
        >
          <input type="text" required name="q" placeholder="SEARCH MENU" />
          <button type="submit">
            <i className="fas fa-search" />
          </button>
        </form>
        <Link to={`/shop/${slug}/profile/dashboard`} className="nav-item">
          <i className="fas fa-user-circle" />
        </Link>
        <Link to={`/shop/${slug}/cart`} className="nav-item">
          <span>
            <i className="fas fa-shopping-basket" />
            <p className="cart-count">{getProductsCount(products)}</p>
          </span>
        </Link>

        <a
          href="#"
          className={`toggle-nav nav-item last ${showNavbar ? 'hidden' : ''}`}
          onClick={() => setShowNavbar(true)}
        >
          <i className="fas fa-bars" />
        </a>
        <a
          href="#"
          className={`times nav-item last ${showNavbar ? 'f-show' : 'hidden'}`}
          onClick={() => setShowNavbar(false)}
        >
          <i className="fas fa-times" />
        </a>
      </div>
    </section>
  );
};

export default ShopNavbar;
