import {call, put, all, takeLatest, select} from 'redux-saga/effects';

import {push} from 'connected-react-router';
import apiUrl from '../../Configs/ApiUrl';
import ApiCall from '../../Services/ApiCall';

import {FETCH_MENU, FETCH_SHOP} from './constants';
import {
  fetchMenuError,
  fetchMenuSuccess,
  fetchShopError,
  fetchShopSuccess,
} from './actions';
import GetHeaders from '../../Configs/GetHeaders';
import {clearUser} from '../Authentication/actions';
import {makeSelectShop} from './selectors';

export function* fetchMenu(action) {
  const requestUrl = `${apiUrl}/menus/byBranchSlugWithProducts/${action.slug}`;
  try {
    const menu = yield call(ApiCall, requestUrl, GetHeaders);
    yield put(fetchMenuSuccess(menu || []));
  } catch (err) {
    yield put(fetchMenuError(err.message));
  }
}

export function* fetchBranch({slug}) {
  const oldShop = yield select(makeSelectShop());
  const requestUrl = `${apiUrl}/branches/byBranchSlug/${slug}`;
  try {
    const shop = yield call(ApiCall, requestUrl, GetHeaders);
    yield put(
      fetchShopSuccess({
        ...oldShop,
        ...shop,
        client_coords: oldShop.client_coords,
        miles: oldShop.miles,
      }),
    );
  } catch (err) {
    // console.log('err occurred', err);
    if (err.message === 'Unauthorized') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(fetchShopError(err.message));
    }
  }
}

function* menuSaga() {
  yield takeLatest(FETCH_MENU, fetchMenu);
}

function* shopSaga() {
  yield takeLatest(FETCH_SHOP, fetchBranch);
}

export default function* rootSaga() {
  yield all([menuSaga(), shopSaga()]);
}
