import React from 'react';
import Master from '../Master';
import Hero from '../../Components/Hero';
import Suggestions from '../../Components/Suggestions';
import Offers from '../../Components/Offers';
import Navbar from '../../Components/Navbar';

const Home = () => {
  return (
    <Master navbar={Navbar}>
      <section id="home">
        <Hero />
        <Suggestions />
        <Offers />
      </section>
    </Master>
  );
};

export default Home;
