import React from 'react';
import {useSelector} from 'react-redux';
import Master from '../Master';
import ShopNavbar from '../../Components/ShopNavbar';
import Directions from './Directions';
import {checkIfObjectEmpty} from '../../Libs/object';
import FullLoader from '../../Components/FullLoader';
import {makeSelectShop} from '../Shop/selectors';
import NotFound404 from '../../Components/404';
import ShopOperations from './ShopOperations';

function Details() {
  const shop = useSelector(makeSelectShop());
  return (
    <NotFound404
      title="Shop Not Found"
      message="Sorry, the shop you requested could not be found or is no longer available."
      condition={checkIfObjectEmpty(shop)}
    >
      <Master navbar={ShopNavbar}>
        <section id="shop-details">
          {checkIfObjectEmpty(shop) ? (
            <div className="column">
              <FullLoader />
            </div>
          ) : (
            <>
              <div className="left">
                <div className="header">
                  <h1>{shop.title}</h1>
                  <h2>/{shop.locality}</h2>
                </div>
                <div className="modes">
                  <div className="row">
                    <p className="label">Click & Collect</p>
                    <p className="value">
                      {shop.takeaway === 1 ? 'Yes' : 'No'}{' '}
                      <strong>
                        (Wait Approx {shop.takeaway_time_taken} minutes)
                      </strong>
                    </p>
                  </div>
                  <div className="row">
                    <p className="label">Delivery</p>
                    <p className="value">
                      {shop.delivery === 1 ? 'Yes' : 'No'}{' '}
                      <strong>
                        (Wait Approx {shop.delivery_time_taken} minutes)
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="shop-specs">
                  <div className="row">
                    <p className="label">Minimum Takeaway Order</p>
                    <p className="value">£{shop.min_takeaway_order}</p>
                  </div>
                  <div className="row">
                    <p className="label">Minimum Delivery Order</p>
                    <p className="value">£{shop.min_delivery_order}</p>
                  </div>
                  <div className="row">
                    <p className="label">Delivery Radius</p>
                    <p className="value">{shop.miles || 0} miles</p>
                  </div>
                  <div className="row">
                    <p className="label">Delivery Postcodes</p>
                    <p className="value">
                      {shop.postcodes?.map((postcode) => `${postcode}, `)}
                    </p>
                  </div>
                </div>
                <ShopOperations
                  takeaway={shop.takeaway_schedule}
                  delivery={shop.delivery_schedule}
                />
              </div>
              <div className="right">
                <div className="contact">
                  <h3>CONTACT DETAILS</h3>
                  <div className="row">
                    <div className="label">Telephone</div>
                    <div className="value">{shop.mobile}</div>
                  </div>
                  <div className="row">
                    <div className="label">Address</div>
                    <div className="value">{shop.address}</div>
                  </div>
                  <div className="row">
                    <div className="label">&nbsp;</div>
                    <div className="value">
                      <a
                        target="_blank"
                        href={`https://www.google.co.uk/maps/dir//${shop.address} ${shop.zipcode}`}
                      >
                        DIRECTIONS
                      </a>
                    </div>
                  </div>
                </div>
                <Directions
                  location={{
                    lat: parseFloat(shop.lat),
                    lng: parseFloat(shop.lon),
                  }}
                />
              </div>
            </>
          )}
        </section>
      </Master>
    </NotFound404>
  );
}

export default Details;
