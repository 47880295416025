/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
import findIndex from 'lodash/findIndex';
import {checkIfObjectEmpty} from './object';

export const productPrice = (
  {price_takeaway, price_delivery, quantity, tiers, discounts = []},
  mode,
) => {
  const pPrices = getItemPrice({price_takeaway, price_delivery}, tiers[0]);
  const pPrice = priceByMode(pPrices, mode);
  const discountedPrice = getDiscountPrice(
    discounts[mode.toLowerCase()],
    pPrice,
  );
  const extraPrices = getExtrasPrices(tiers, mode);
  return (discountedPrice + extraPrices) * quantity;
};

export const getExtrasPrices = (tiers, mode) => {
  return tiers.reduce((total, tier) => getTierPrice(tier, mode) + total, 0);
};

export const onlyPrice = ({price_takeaway, price_delivery}, mode) => {
  return priceByMode({price_takeaway, price_delivery}, mode);
};

export const onlyPriceWithDiscount = (
  {price_takeaway, price_delivery, discounts},
  mode,
) => {
  const price = priceByMode({price_takeaway, price_delivery}, mode);
  return getDiscountPrice(discounts[mode.toLowerCase()], price);
};

export const productPriceWithoutQuantity = (
  {price_delivery, price_takeaway, tiers, discounts},
  mode,
) => {
  const pPrices = getItemPrice({price_delivery, price_takeaway}, tiers[0]);
  const pPrice = priceByMode(pPrices, mode);
  const discountedPrice = getDiscountPrice(
    discounts[mode.toLowerCase()],
    pPrice,
  );
  return discountedPrice + getExtrasPrices(tiers, mode);
};

export const cartTotal = (products, mode) => {
  return products.reduce(
    (total, product) => productPrice(product, mode) + total,
    0,
  );
};

export const getItemPrice = (price, size) => {
  if (size) {
    if (size.type === 'Size') {
      return size.selected.price;
    }
    return price;
  }
  return price;
};

export const getTierPrice = (tier = {}, mode) => {
  if (!tier) {
    return 0;
  }
  if (tier.type === 'Option') {
    return getOptionPrice(tier.selected, mode);
  }
  if (tier.type === 'Addon') {
    return getAddonsPrice(tier.selected, mode);
  }
  if (tier.type === 'Product') {
    return getTierProductPrice(tier.selected, mode);
  }
  return 0;
};

export const getTierProductPrice = (product, mode) => {
  return priceByMode(product.price, mode);
};

export const getSizePrice = (tier) => {
  return tier.selected.price;
};

export const getOptionPrice = (option, mode) => {
  return priceByMode(option.price, mode);
};

export const getAddonsPrice = (addons, mode) =>
  addons.paid?.reduce(
    (total, addon) => getAddonPrice(addon, mode) + total,
    0,
  ) || 0;

export const getAddonPrice = (addon, mode) => {
  return priceByMode(addon.price, mode) * addon.quantity;
};

export const priceBySize = (item, {selected: {id}}) => {
  const index = findIndex(item.price, (price) => id === price.size_id);
  if (index === -1) {
    return 0;
  }
  return item.price[index];
};

export const getDeliveryCharges = (operation, mode, total) => {
  if (checkIfObjectEmpty(operation)) {
    return 0;
  }
  if (mode === 'Delivery') {
    if (operation.delivery_type === 'cap') {
      if (total >= operation.delivery_cap) {
        return parseInt(operation.delivery_charges, 10);
      }
      return 0;
    }
    return parseInt(operation.delivery_charges, 10);
  }
  if (mode === 'Takeaway') {
    return 0;
  }
  return 0;
};

export const getVoucherPrice = (voucher, total) => {
  let voucherAmount = 0;
  if (checkIfObjectEmpty(voucher)) {
    voucherAmount = 0;
  }
  if (voucher.type === 'fixed') {
    voucherAmount = voucher.amount;
  }
  if (voucher.type === 'percentage') {
    voucherAmount = Math.round(total * (voucher.amount / 100));
  }
  if (voucher.type === 'cap') {
    if (total >= voucher.total) {
      voucherAmount = voucher.amount;
    } else {
      voucherAmount = 0;
    }
  }
  if (voucherAmount > total) {
    return 0;
  }
  return voucherAmount;
};

export const getProperPrice = (item, s) => {
  if (s) {
    const size = s.toJS();
    if (size.type === 'Size') {
      return priceBySize(item, size);
    }
    return item?.price[0];
  }
  return item?.price[0];
};

export const priceByMode = (prices, mode) => {
  if (mode === 'Takeaway') {
    return prices.price_takeaway;
  }
  if (mode === 'Delivery') {
    return prices.price_delivery;
  }
  return 0;
};

export const getDiscountPrice = (discount, price) => {
  if (!discount || checkIfObjectEmpty(discount)) {
    return price;
  }
  if (discount.type === 'fixed') {
    return parseInt(price - discount.amount, 10);
  }
  return parseInt(price - (price * discount.amount) / 100, 10);
};
