/* eslint-disable react/destructuring-assignment */
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {map} from 'lodash';
import {fromJS} from 'immutable';
import {toast} from 'react-toastify';
import Master from '../Master';
import ShopNavbar from '../../Components/ShopNavbar';
import SideCart from '../../Components/SideCart';
import apiCall from '../../Services/ApiCall';
import GetHeaders from '../../Configs/GetHeaders';
import apiUrl from '../../Configs/ApiUrl';
import Price from '../../Components/Price';
import Header from '../../Components/Header';
import Sizes from './Sizes';
import Items from './Items';
import Addons from './Addons';
import Options from './Options';
import Loadable from '../../Components/Loadable';
import FullLoader from '../../Components/FullLoader';
import {getProperPrice, productPrice} from '../../Libs/prices';
import {
  addAddonToTier,
  checkIfProductCompleted,
  getAdditionalTiers,
} from '../../Libs/product';
import OriginalPrice from '../../Components/OriginalPrice';
import {storeStatus} from '../../Libs/store';
import Clickable from './Clickable';
import {checkIfObjectEmpty} from '../../Libs/object';

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 'initial',
      product: {},
      currentTier: 0,
      selected: fromJS({
        id: null,
        tiers: [],
      }),
    };
  }

  componentDidMount() {
    this.setState({state: 'loading'});
    const {
      onFetchShop,
      match: {
        params: {product, slug},
      },
    } = this.props;
    onFetchShop(slug);

    apiCall(`${apiUrl}/products/byProductSlug/${product}`, GetHeaders)
      .then((prod) => {
        const {tiers, ...rest} = prod;
        this.setState({
          product: prod,
          state: 'success',
          selected: fromJS({...rest, tiers: [], quantity: 1, clickable: {}}),
        });
      })
      .catch(() => {
        this.setState({state: 'error'});
      });
  }

  onSelectOption = (index, option, tier) => {
    const {selected, currentTier} = this.state;
    if (selected.getIn(['tiers', index]) === undefined) {
      this.setState({currentTier: currentTier + 1});
    }
    this.setState({
      selected: selected.setIn(
        ['tiers', index],
        fromJS({
          ...tier,
          selected: {
            ...option,
            price: getProperPrice(option, selected.getIn(['tiers', 0])),
            quantity: 1,
          },
        }),
      ),
    });
  };

  onSelectSize = (index, size, tier) => {
    const {selected, currentTier} = this.state;
    if (selected.getIn(['tiers', index]) === undefined) {
      this.setState({currentTier: currentTier + 1});
    }
    this.setState({
      selected: selected.set(
        'tiers',
        fromJS([
          {
            ...tier,
            selected: {
              ...size,
              price: getProperPrice(size),
              quantity: 1,
            },
          },
        ]),
      ),
    });
  };

  onSelectItem = (index, item, tier, isClickable = false) => {
    const {selected, currentTier} = this.state;
    if (selected.getIn(['tiers', index]) === undefined) {
      this.setState({currentTier: currentTier + 1});
    }
    const prices = getProperPrice(item, selected.getIn(['tiers', 0]));
    this.setState({
      selected: selected.setIn(
        ['tiers', index],
        fromJS({
          ...tier,
          selected: {
            ...item,
            price: isClickable
              ? prices
              : {price_takeaway: 0, price_delivery: 0},
            quantity: 1,
          },
        }),
      ),
    });
  };

  onSelectAddons = (index, addon, tier) => {
    const {selected, currentTier} = this.state;
    if (selected.getIn(['tiers', index]) === undefined) {
      this.setState({currentTier: currentTier + 1});
    }
    this.setState({
      selected: addAddonToTier(selected, index, addon, tier),
    });
  };

  onAddToCart = () => {
    const {selected, product} = this.state;
    const {onAddToCart, history} = this.props;
    if (checkIfProductCompleted(selected.toJS(), product)) {
      onAddToCart(selected.toJS());
      history.goBack();
    } else {
      toast.error('Please select all required options before adding to cart.', {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  onChangeQuantity = (e) => {
    const {selected} = this.state;
    const quantity = selected.get('quantity');
    if (e.keyCode === 38) {
      this.setState({
        selected: selected.set('quantity', quantity + 1),
      });
    }
    if (e.keyCode === 40 && quantity > 1) {
      this.setState({
        selected: selected.set('quantity', quantity - 1),
      });
    }
  };

  onUpdateQuantity = (e) => {
    const {selected} = this.state;
    this.setState({
      selected: selected.set('quantity', parseInt(e.target.value || 0, 10)),
    });
  };

  onIncrementQuantity = () => {
    const {selected} = this.state;
    const quantity = selected.get('quantity');
    this.setState({selected: selected.set('quantity', quantity + 1)});
  };

  onDecrementQuantity = () => {
    const {selected} = this.state;
    const quantity = selected.get('quantity');
    if (quantity > 1) {
      this.setState({selected: selected.set('quantity', quantity - 1)});
    }
  };

  removeAddon = (tIndex, tag, aIndex) => {
    const {selected} = this.state;
    this.setState({
      selected: selected.deleteIn(['tiers', tIndex, 'selected', tag, aIndex]),
    });
  };

  changeAddonQuantity = (tIndex, tag, addonId, quantity) => {
    const {selected} = this.state;
    const addonIndex = selected
      .getIn(['tiers', tIndex, 'selected', tag])
      .findIndex((addon) => addon.id === addonId);
    this.setState({
      selected: selected.setIn(
        ['tiers', tIndex, 'selected', tag, addonIndex, 'quantity'],
        quantity,
      ),
    });
  };

  handleSelectClickable = (clickable) => {
    const {selected} = this.state;
    this.setState({
      selected: selected.set('clickable', fromJS(clickable)),
    });
  };

  handleRemoveClickable = () => {
    const {selected, product} = this.state;
    const clickable = selected.get('clickable').toJS();
    const extraTiers = getAdditionalTiers(product.specific_tiers, clickable);
    const tierIds = map(extraTiers, 'id');
    this.setState({
      selected: selected
        .set('clickable', fromJS({}))
        .update('tiers', (selectedTiers) =>
          selectedTiers.filter((tier) => !tierIds.includes(tier.get('id'))),
        ),
    });
  };

  render() {
    const {selected} = this.state;
    const {
      match: {
        params: {slug},
      },
      shop,
      mode,
    } = this.props;
    const {state, product, currentTier} = this.state;

    if (state === 'initial' || state === 'loading') {
      return (
        <Master navbar={ShopNavbar}>
          <section id="product">
            <div className="column f-3">
              <FullLoader active />
            </div>
          </section>
        </Master>
      );
    }

    if (state === 'error') {
      return (
        <Master navbar={ShopNavbar}>
          <section id="items-not-found" className="row">
            <h1>Product Not Found</h1>
            <p>Something went wrong!</p>
            <Link to={`/${shop.slug}/menu`}>CLICK HERE TO RETURN TO MENU</Link>
          </section>
        </Master>
      );
    }

    const additionalTiers = getAdditionalTiers(
      selected.get('clickable')?.toJS(),
      selected.get('specific_tiers')?.toJS(),
    );

    return (
      <Master navbar={ShopNavbar}>
        <section id="product">
          <Header title={product.title} />
          <div className="column">
            <div className="img">
              <img src={product.image} alt={product.title} />
            </div>
          </div>
          <div className="column f-2">
            <div className="customize">
              <div className="head">
                <h1>{product.title}</h1>
                <p>{product.description}</p>
              </div>
              <div className="tiers">
                <div className="head">
                  <h5>SELECT YOUR OPTIONS</h5>
                </div>
                {product.tiers?.map((tier, index) => {
                  switch (tier.type) {
                    case 'Product':
                      return (
                        <Items
                          onSelectItem={this.onSelectItem}
                          index={index}
                          selectedItem={selected.getIn(['tiers', index])}
                          key={tier.title}
                          tier={tier}
                          size={selected.getIn(['tiers', 0])}
                          enabled={currentTier >= index}
                        />
                      );
                    case 'Size':
                      return (
                        <Sizes
                          onSelectSize={this.onSelectSize}
                          index={index}
                          key={tier.title}
                          tier={tier}
                          size={selected.getIn(['tiers', 0])}
                          enabled={currentTier >= index}
                        />
                      );
                    case 'Option':
                      return (
                        <Options
                          onSelectOption={this.onSelectOption}
                          index={index}
                          selectedOption={selected.getIn(['tiers', index])}
                          key={tier.title}
                          tier={tier}
                          size={selected.getIn(['tiers', 0])}
                          enabled={currentTier >= index}
                        />
                      );
                    case 'Addon':
                      return (
                        <Addons
                          onSelectAddons={this.onSelectAddons}
                          index={index}
                          key={tier.title}
                          selectedAddons={selected
                            .getIn(['tiers', index, 'selected'])
                            ?.toJS()}
                          tier={tier}
                          size={selected.getIn(['tiers', 0])}
                          changeAddonQuantity={this.changeAddonQuantity}
                          removeAddon={this.removeAddon}
                          enabled={currentTier >= index}
                        />
                      );
                    default:
                      return null;
                  }
                })}
                <Clickable
                  clickables={selected.get('clickables')?.toJS()}
                  handleSelectClickable={this.handleSelectClickable}
                  selectedClickable={selected.get('clickable').toJS()}
                  handleRemoveClickable={this.handleRemoveClickable}
                />
                {additionalTiers?.map((tier, index) => {
                  switch (tier.type) {
                    case 'Product':
                      return (
                        <Items
                          onSelectItem={(i, item, t) =>
                            this.onSelectItem(i, item, t, true)
                          }
                          index={product.tiers.length + index}
                          selectedItem={selected
                            .getIn(['tiers', product.tiers.length + index])
                            ?.toJS()}
                          key={tier.title}
                          tier={tier}
                          size={selected.getIn(['tiers', 0])}
                          enabled={currentTier >= product.tiers.length + index}
                        />
                      );
                    case 'Option':
                      return (
                        <Options
                          onSelectOption={this.onSelectOption}
                          index={product.tiers.length + index}
                          selectedOption={selected
                            .getIn(['tiers', product.tiers.length + index])
                            ?.toJS()}
                          key={tier.title}
                          tier={tier}
                          size={selected.getIn(['tiers', 0])}
                          enabled={currentTier >= product.tiers.length + index}
                        />
                      );
                    case 'Addon':
                      return (
                        <Addons
                          onSelectAddons={this.onSelectAddons}
                          index={product.tiers.length + index}
                          key={tier.title}
                          selectedAddons={selected
                            .getIn([
                              'tiers',
                              product.tiers.length + index,
                              'selected',
                            ])
                            ?.toJS()}
                          tier={tier}
                          size={selected.getIn(['tiers', 0])}
                          changeAddonQuantity={this.changeAddonQuantity}
                          removeAddon={this.removeAddon}
                          enabled={currentTier >= product.tiers.length + index}
                        />
                      );
                    default:
                      return null;
                  }
                })}
                <div className="tier">
                  <div className="footer">
                    <h2>
                      <OriginalPrice product={product} />
                      <Price>{productPrice(selected.toJS(), mode)}</Price>
                    </h2>
                    <div className="quantity">
                      <div className="label">Qty</div>
                      <button
                        type="button"
                        className="increment"
                        onClick={this.onIncrementQuantity}
                      >
                        <i className="fas fa-plus" />
                      </button>
                      <input
                        autoComplete="off"
                        name="quantity"
                        type="text"
                        value={selected.get('quantity')}
                        onChange={this.onUpdateQuantity}
                        onKeyDown={this.onChangeQuantity}
                      />
                      <button
                        type="button"
                        className="decrement"
                        onClick={this.onDecrementQuantity}
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                    {storeStatus(shop, mode) ? (
                      <button
                        type="button"
                        className="add-to-cart"
                        onClick={this.onAddToCart}
                      >
                        <Loadable color="white" active={state === 'adding'}>
                          Add To Cart
                        </Loadable>
                      </button>
                    ) : (
                      <p>Online Ordering Currently Offline</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="note">
                <p>
                  <strong>Note:&nbsp;</strong>Options marked with{' '}
                  <span className="text-primary">✱</span> are mandatory.
                </p>
              </div>
              <div className="actions">
                <a href={`/shop/${slug}/menu`}>
                  <i className="fas fa-angle-left" />
                  Back
                </a>
              </div>
            </div>
          </div>
          <div className="column shop-side-cart">
            <SideCart />
          </div>
        </section>
      </Master>
    );
  }
}

export default withRouter(Product);
