import React from 'react';
import {HashLink} from 'react-router-hash-link';

const Suggestions = () => {
  return (
    <section className="suggestions-section">
      <div className="suggestions-title">
        <h1>
          <span> OUR FOOD / </span>
          FOOD FOR EVERY OCCASION
        </h1>
      </div>
      <div className="suggestions">
        <HashLink to="/our-food#promotions" className="suggestion">
          <div className="img">
            <img
              src={require('../Assets/images/4-piece-xmas.png')}
              alt="4-piece-xmas"
            />
          </div>
          <h1 className="title">In-Store Promotions</h1>
        </HashLink>
        <HashLink to="/our-food/#family-sharing-meals" className="suggestion">
          <div className="img">
            <img
              src={require('../Assets/images/family-roundhouse-max.png')}
              alt="4-piece-xmas"
            />
          </div>
          <h1 className="title">Family & Sharing Meals</h1>
        </HashLink>
        <HashLink to="/our-food#chicken-meals" className="suggestion">
          <div className="img">
            <img
              src={require('../Assets/images/2-pc-meal.png')}
              alt="4-piece-xmas"
            />
          </div>
          <h1 className="title">Chicken Meals</h1>
        </HashLink>
        <HashLink to="/our-food#chicken-burger-meals" className="suggestion">
          <div className="img">
            <img
              src={require('../Assets/images/fof-meal.png')}
              alt="4-piece-xmas"
            />
          </div>
          <h1 className="title">Chicken Burger Meals</h1>
        </HashLink>
        <HashLink to="/our-food#boneless-chicken-meals" className="suggestion">
          <div className="img">
            <img
              src={require('../Assets/images/boneless-meal.png')}
              alt="4-piece-xmas"
            />
          </div>
          <h1 className="title">Boneless Chicken Meals</h1>
        </HashLink>
        <HashLink to="/our-food#starter-snaks-sides" className="suggestion">
          <div className="img">
            <img
              src={require('../Assets/images/ribs-meal-1.png')}
              alt="4-piece-xmas"
            />
          </div>
          <h1 className="title">BBQ Rib Meals</h1>
        </HashLink>
      </div>
    </section>
  );
};

export default Suggestions;
