import React from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = () => (
  <img
    width={40}
    height={50}
    src={require('../../Assets/images/icon-map-marker-favorite.png')}
    alt="Favorite Chicken"
  />
);
const Directions = ({location}) => (
  <div id="directions">
    <GoogleMapReact
      bootstrapURLKeys={{key: 'AIzaSyDvv6ZhBhV4rHG5EP_pGDi4blouzI_9VhU'}}
      defaultCenter={location}
      defaultZoom={11}
    >
      <AnyReactComponent lat={location.lat} lng={location.lng} />
    </GoogleMapReact>
  </div>
);

export default Directions;
