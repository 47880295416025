import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {makeSelectCheckoutMode} from '../Pages/Cart/selectors';
import {changeCheckoutMode} from '../Pages/Cart/actions';

function CheckoutMode() {
  const mode = useSelector(makeSelectCheckoutMode());
  const dispatch = useDispatch();
  return (
    <>
      <p className="checkout-mode-label">Please select checkout method.</p>
      <div className="checkout-mode">
        <div
          className="mode alert-info delivery"
          onClick={() => dispatch(changeCheckoutMode('Delivery'))}
        >
          {mode === 'Delivery' && (
            <span>
              <i className="fas fa-check" />
            </span>
          )}
          <p>DELIVERY</p>
        </div>
        <div
          className="mode alert-info takeaway"
          onClick={() => dispatch(changeCheckoutMode('Takeaway'))}
        >
          {mode === 'Takeaway' && (
            <span>
              <i className="fas fa-check" />
            </span>
          )}
          <p>COLLECTION</p>
        </div>
      </div>
    </>
  );
}

export default CheckoutMode;
