import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {makeSelectShop} from '../Pages/Shop/selectors';
import {checkIfObjectEmpty} from '../Libs/object';

const Footer = () => {
  const shop = useSelector(makeSelectShop());
  return (
    <div className="footer-wrapper clearfix">
      {!checkIfObjectEmpty(shop) ? (
        <div className="row shop-details">
          <h5>{shop.title}</h5>
          <p className="address">{shop.address}</p>
          <p className="mobile">{shop.mobile}</p>
        </div>
      ) : null}
      <div className="row">
        <div className="footer-left">
          <div className="shortcuts">
            <Link className="s-item" to="/cookies">
              COOKIES
            </Link>
            <Link className="s-item" to="/privacy-policy">
              PRIVACY
            </Link>
            <Link className="s-item" to="/terms-conditions">
              TERMS & CONDITIONS
            </Link>
            <Link className="s-item" to="/terms-of-sale">
              TERMS OF SALE
            </Link>
            <Link className="s-item last" to="/sitemap">
              SITEMAP
            </Link>
          </div>
          <div className="footer-info">
            <p>
              4 Broadwalk, Crawley, Sussex, RH10 1HQ, United Kingdom |
              01293731273
            </p>
            <p>Company Reg No. 12730565</p>
          </div>
          <div className="social">
            <a target="_blank" href="https://www.facebook.com/FavoriteChicken">
              <i className="fab fa-facebook-f" />
            </a>
            <a target="_blank" href="https://twitter.com/FavoriteChicken">
              <i className="fab fa-twitter" />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/favoritechickenribs"
            >
              <i className="fab fa-instagram" />
            </a>
            <div className="news-letter">
              <a
                target="_blank"
                href="http://eepurl.com/NqOJX"
                className="newsletter"
              >
                SIGN UP TO OUR NEWSLETTER
              </a>
            </div>
          </div>
        </div>
        <div className="footer-right">
          <a
            className="calling-card"
            target="_blank"
            href="https://www.sapphireglobaltech.com/"
          >
            Website Designed and Developed by Sapphire Global Technologies.
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
