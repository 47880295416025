import React from 'react';
import moment from 'moment';

const days = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];
function TodayDeliveryOperation({operation}) {
  const day = moment().day();
  return (
    <div className="operation">
      <h3>
        {operation.deliveryDayStatus[days[day]] === 1
          ? 'Delivering Today'
          : 'Delivery Closed Today'}
      </h3>
      <p>{`${operation.deliveryFrom[day]}-${operation.deliveryTo[day]}`}</p>
    </div>
  );
}

export default TodayDeliveryOperation;
