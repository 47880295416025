import React from 'react';
import moment from 'moment';

const days = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

function TodayStoreOperation({operation}) {
  const day = moment().day();
  return (
    <div className="operation">
      <h3>
        {operation.day_status[days[day]] === 1
          ? 'Store Open Today'
          : 'Store Closed Today'}
      </h3>
      <p>{`${operation.from[day]}-${operation.to[day]}`}</p>
    </div>
  );
}

export default TodayStoreOperation;
