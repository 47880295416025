import React from 'react';
import {useHistory} from 'react-router-dom';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';

const Hero = () => {
  const history = useHistory();
  return (
    <section id="hero">
      <Slider autoplay={5000}>
        <div>
          <div className="hero-wrapper">
            <img
              className="hero-img"
              src={require('../Assets/images/hero1.jpg')}
              alt="Hero 1"
            />
          </div>
          <div className="hero-footer">
            <h1>We&lsquo;re Getting Through This Together.</h1>
            <p>
              Almost all of our stores are now open and we want to say a big
              &lsquo;Thank You&lsquo; for your support during the past months.
              Use our store finder to check current opening times and to see
              which stores are taking orders in-store, offering Click&Collect
              and delivery - either via our website, Deliveroo, JustEat or
              UberEats (depending on location).
            </p>
          </div>
        </div>
        <div>
          <div className="hero-wrapper">
            <img
              className="hero-img"
              src={require('../Assets/images/hero2.png')}
              alt="Hero 1"
            />
          </div>
          <div className="hero-footer">
            <h1>MEET THE NEW 50/50 SUPERSUB!</h1>
            <p>
              Introducing our NEW sub - everything you could want in a deli
              roll. We&lsquo;ve got two succulent crispy coated chicken fillets,
              side by side, topped with lettuce & cool mayo. The best bit is -
              you get the very best of Favorite inside!! One fillet is
              traditional, the other fillet is spicy marinated, then coated &
              cooked the Favorite way. So, you get tender pieces of chicken; one
              HOT, one NOT.{' '}
            </p>
          </div>
        </div>
        <div>
          <div className="hero-wrapper">
            <img
              className="hero-img"
              src={require('../Assets/images/hero1.jpg')}
              alt="Hero 1"
            />
          </div>
          <div className="hero-footer">
            <h1>We&lsquo;re Getting Through This Together.</h1>
            <p>
              Almost all of our stores are now open and we want to say a big
              &lsquo;Thank You&lsquo; for your support during the past months.
              Use our store finder to check current opening times and to see
              which stores are taking orders in-store, offering Click&Collect
              and delivery - either via our website, Deliveroo, JustEat or
              UberEats (depending on location).
            </p>
          </div>
        </div>
        <div>
          <div className="hero-wrapper">
            <img
              className="hero-img"
              src={require('../Assets/images/hero2.png')}
              alt="Hero 1"
            />
          </div>
          <div className="hero-footer">
            <h1>MEET THE NEW 50/50 SUPERSUB!</h1>
            <p>
              Introducing our NEW sub - everything you could want in a deli
              roll. We&lsquo;ve got two succulent crispy coated chicken fillets,
              side by side, topped with lettuce & cool mayo. The best bit is -
              you get the very best of Favorite inside!! One fillet is
              traditional, the other fillet is spicy marinated, then coated &
              cooked the Favorite way. So, you get tender pieces of chicken; one
              HOT, one NOT.{' '}
            </p>
          </div>
        </div>
      </Slider>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          history.push(`/find-store/${e.target[0].value}`);
        }}
      >
        <div className="label">FIND YOUR NEAREST STORE</div>
        <input className="postcode" type="text" placeholder="YOUR POSTCODE" />
        <button className="submit" type="submit">
          GO
        </button>
      </form>
    </section>
  );
};

export default Hero;
