import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import {NavHashLink} from 'react-router-hash-link';
import {useDispatch, useSelector} from 'react-redux';
import {makeSelectShop} from '../Pages/Shop/selectors';
import {checkIfObjectEmpty} from '../Libs/object';
import {deselectShop} from '../Pages/Shop/actions';

const Navbar = () => {
  const {postcode} = useParams();
  const dispatch = useDispatch();
  const {slug} = useParams();
  const [q, setQ] = useState(postcode || '');
  const [showNavbar, setShowNavbar] = useState(false);
  const shop = useSelector(makeSelectShop());
  const {
    location: {pathname},
    push,
  } = useHistory();
  useEffect(() => {
    if (!checkIfObjectEmpty(shop)) {
      dispatch(deselectShop());
    }
  });
  return (
    <section className="navbar" id={showNavbar ? 'toggled-navbar' : ''}>
      <Link to={slug ? `/shop/${slug}/menu` : '/'} className="nav-logo" />
      <ul className="nav">
        <li className="nav-item dropdown">
          <button
            type="button"
            className={`dropbtn ${
              pathname === `/our-food/order-favorite` ? 'active-nav' : ''
            }`}
          >
            ORDER NOW
          </button>
          <ul className="dropdown-content">
            <li>
              <Link to="/our-food/order-favorite">Order Favorite</Link>
            </li>
            {/* <li> */}
            {/*  <NavHashLink smooth to="/our-food/order-favorite#apps"> */}
            {/*    DOWNLOAD APP */}
            {/*  </NavHashLink> */}
            {/* </li> */}
          </ul>
        </li>
        <li className="nav-item dropdown">
          <a
            className={`dropbtn ${
              pathname === `/our-food` || pathname === '/our-food/allergens'
                ? 'active-nav'
                : ''
            }`}
          >
            OUR FOOD
          </a>
          <ul className="dropdown-content">
            <li>
              <Link to="/our-food">FAVORITE FOOD</Link>
            </li>
            <li>
              <Link to="/our-food/allergens">ALLERGENS</Link>
            </li>
          </ul>
        </li>
        <li className="nav-item dropdown">
          <a
            className={`dropbtn ${
              pathname === `/about` || pathname === '/contact-us'
                ? 'active-nav'
                : ''
            }`}
          >
            ABOUT
          </a>
          <ul className="dropdown-content">
            <li>
              <Link to="/about">OUR STORY</Link>
            </li>
            <li>
              <NavHashLink smooth to="/about#faqs">
                CUSTOMER FAQS
              </NavHashLink>
            </li>
            <li>
              <Link to="/contact-us">CONTACT US</Link>
            </li>
          </ul>
        </li>
        <li className="nav-item dropdown last">
          <a className={`dropbtn ${pathname === `/jobs` ? 'active-nav' : ''}`}>
            JOBS
          </a>
          <ul className="dropdown-content">
            <li>
              <Link to="/jobs">IN-STORE JOBS</Link>
            </li>
          </ul>
        </li>
      </ul>
      <form
        className="search"
        onSubmit={() => {
          push(`/find-store/${q}`);
        }}
      >
        <Link className="link" to="/find-store">
          <i className="fas fa-map-marker-alt search-icon" />
          <h4 className="search-label">FIND YOUR NEAREST STORE</h4>
        </Link>
        <input
          className="search-input post-code-form"
          required
          value={q}
          type="text"
          name="postcode"
          placeholder="ENTER YOUR POSTCODE"
          onChange={(e) => setQ(e.target.value)}
        />
        <button className="search-button post-code-form" type="submit">
          GO
        </button>
        {/* <Link to="our-food/order-favorite#apps" className="download-apps"> */}
        {/*  DOWNLOAD APP */}
        {/* </Link> */}
        <Link to="/find-store" className="find-store">
          <i className="fas fa-map-marker-alt" />
        </Link>
        <a
          href="#"
          className={`toggle-nav ${showNavbar ? 'hidden' : ''}`}
          onClick={() => setShowNavbar(true)}
        >
          <i className="fas fa-bars" />
        </a>
        <a
          href="#"
          className={`times ${showNavbar ? 'f-show' : 'hidden'}`}
          onClick={() => setShowNavbar(false)}
        >
          <i className="fas fa-times" />
        </a>
      </form>
    </section>
  );
};

export default Navbar;
