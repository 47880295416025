import React from 'react';

function Offers() {
  return (
    <section className="offers">
      <div className="offer-bg" />
      <div className="offer">
        <div className="header-image-wraper">
          <h2 className="header">WINGER WARMER</h2>
          <div className="img">
            <img
              src={require('../Assets/images/winger-warmer-instore-promo-web.png')}
              alt="WINGER WARMER"
            />
          </div>
        </div>
        <div className="ingredients">
          <h3>INCLUDES</h3>
          <p>6 pieces Favorite Chicken</p>
          <p>6 Favorite Wings</p>
          <p>4 Regular Fries</p>
        </div>
      </div>
      <div className="offer">
        <div className="header-image-wraper">
          <h2 className="header">SNACK IT!</h2>
          <div className="img">
            <img
              src={require('../Assets/images/offer-3.png')}
              alt="WINGER WARMER"
            />
          </div>
        </div>
        <div className="ingredients">
          <h3>INCLUDES</h3>
          <p>Choice of either:</p>
          <p>2 Favorite Wings,</p>
          <p>1 piece Favorite Chicken,</p>
          <p>1 Crispy Fillet Strip or Snack Poppas</p>
          <p>with Regular Fries</p>
        </div>
      </div>
      <div className="offer">
        <div className="header-image-wraper">
          <h2 className="header">FAMILY VALUE</h2>
          <div className="img">
            <img
              src={require('../Assets/images/fvm-480280.png')}
              alt="WINGER WARMER"
            />
          </div>
        </div>
        <div className="ingredients">
          <h3>INCLUDES</h3>
          <p>8 or 12 pieces Favorite Chicken</p>
          <p>4 Regular Fries</p>
          <p>2 Large Tub Sides</p>
          <p>4 Jam Doughnuts</p>
        </div>
      </div>
    </section>
  );
}

export default Offers;
