import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Topbar from '../Components/Topbar';
import Footer from '../Components/Footer';
import Header from '../Components/Header';

const TOP = 98;

const Master = ({children, navbar}) => {
  const {pathname} = useLocation();
  const Navbar = navbar;
  useEffect(() => {
    if (window.pageYOffset > TOP) {
      document.body.scrollTop = TOP; // For Safari
      document.documentElement.scrollTop = TOP;
    }
  }, [pathname]);
  return (
    <>
      <Header title="Favorite - Britain's Tastiest Chicken!" />
      <header id="header">
        <Topbar />
        <Navbar />
      </header>
      <section id="content">{children}</section>
      <footer id="footer">
        <Footer />
      </footer>
    </>
  );
};

export default Master;
