import firebase from 'firebase/app';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyD57PzSUAli0HAbKAxYRZfbkZAEfBrPA8A',
  authDomain: 'sapphire-bookingapproval.firebaseapp.com',
  databaseURL: 'https://sapphire-bookingapproval.firebaseio.com',
  projectId: 'sapphire-bookingapproval',
  storageBucket: 'sapphire-bookingapproval.appspot.com',
  messagingSenderId: '781819412037',
  appId: '1:781819412037:web:16ed6c1d63dee4cfa6ad2c',
  measurementId: 'G-3DCL6FVY9X',
};

firebase.initializeApp(config);

export default firebase;
