import React from 'react';

function ShopOperations({takeaway, delivery}) {
  return (
    <div className="operations">
      <h3>OPENING HOURS</h3>
      <div>
        <div className="head row">
          <p> &nbsp;</p>
          <p>Store Hours</p>
          <p>Delivery Hours</p>
        </div>
        <div className="row">
          <p>Monday</p>
          <p>
            {takeaway.takeawayDayStatus.monday === 1
              ? `${takeaway.takeawayFrom[1]}-${takeaway.takeawayTo[1]}`
              : 'OFFLINE'}
          </p>
          <p>
            {delivery.deliveryDayStatus.monday === 1
              ? `${delivery.deliveryFrom[1]}-${delivery.deliveryTo[1]}`
              : 'OFFLINE'}
          </p>
        </div>
        <div className="row">
          <p>Tuesday</p>
          <p>
            {takeaway.takeawayDayStatus.monday === 1
              ? `${takeaway.takeawayFrom[2]}-${takeaway.takeawayTo[2]}`
              : 'OFFLINE'}
          </p>
          <p>
            {delivery.deliveryDayStatus.monday === 1
              ? `${delivery.deliveryFrom[2]}-${delivery.deliveryTo[2]}`
              : 'OFFLINE'}
          </p>
        </div>
        <div className="row">
          <p>Wednesday</p>
          <p>
            {takeaway.takeawayDayStatus.monday === 1
              ? `${takeaway.takeawayFrom[3]}-${takeaway.takeawayTo[3]}`
              : 'OFFLINE'}
          </p>
          <p>
            {delivery.deliveryDayStatus.monday === 1
              ? `${delivery.deliveryFrom[3]}-${delivery.deliveryTo[3]}`
              : 'OFFLINE'}
          </p>
        </div>
        <div className="row">
          <p>Thursday</p>
          <p>
            {takeaway.takeawayDayStatus.monday === 1
              ? `${takeaway.takeawayFrom[4]}-${takeaway.takeawayTo[4]}`
              : 'OFFLINE'}
          </p>
          <p>
            {delivery.deliveryDayStatus.monday === 1
              ? `${delivery.deliveryFrom[4]}-${delivery.deliveryTo[4]}`
              : 'OFFLINE'}
          </p>
        </div>
        <div className="row">
          <p>Friday</p>
          <p>
            {takeaway.takeawayDayStatus.monday === 1
              ? `${takeaway.takeawayFrom[5]}-${takeaway.takeawayTo[5]}`
              : 'OFFLINE'}
          </p>
          <p>
            {delivery.deliveryDayStatus.monday === 1
              ? `${delivery.deliveryFrom[5]}-${delivery.deliveryTo[5]}`
              : 'OFFLINE'}
          </p>
        </div>
        <div className="row">
          <p>Saturday</p>
          <p>
            {takeaway.takeawayDayStatus.monday === 1
              ? `${takeaway.takeawayFrom[6]}-${takeaway.takeawayTo[6]}`
              : 'OFFLINE'}
          </p>
          <p>
            {delivery.deliveryDayStatus.monday === 1
              ? `${delivery.deliveryFrom[6]}-${delivery.deliveryTo[6]}`
              : 'OFFLINE'}
          </p>
        </div>
        <div className="row">
          <p>Sunday</p>
          <p>
            {takeaway.takeawayDayStatus.monday === 1
              ? `${takeaway.takeawayFrom[0]}-${takeaway.takeawayTo[0]}`
              : 'OFFLINE'}
          </p>
          <p>
            {delivery.deliveryDayStatus.monday === 1
              ? `${delivery.deliveryFrom[0]}-${delivery.deliveryTo[0]}`
              : 'OFFLINE'}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ShopOperations;
