import React, {useState} from 'react';

const Marker = ({store}) => {
  const [show, setShow] = useState(false);
  return (
    <div className={`marker-wrapper ${show && 'show'}`}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <img
        onClick={() => setShow(!show)}
        width={30}
        height={35}
        src={require('../Assets/images/icon-map-marker-favorite.png')}
        alt="Favorite Chicken"
      />
      {show && (
        <div className="info-window">
          <h3>{store.title}</h3>
          <p>Tel:{store.mobile}</p>
          <p>{store.address}</p>
          <a
            rel="noopener noreferrer"
            href={`https://www.google.co.uk/maps/dir//${store.address} ${store.zipcode}`}
            target="_blank"
          >
            Get directions
          </a>
        </div>
      )}
    </div>
  );
};

export default Marker;
